import * as React from "react";
import {
  Admin,
  Resource,
  createMuiTheme,
  resolveBrowserLocale,
} from "react-admin";
// import jsonServerProvider from 'ra-data-json-server';
import { GalleryList, GalleryCreate, GalleryEdit } from "./Gallery";
import {
  SubscriptionList,
  SubscriptionCreate,
  SubscriptionEdit,
} from "./Subscription";
import { TransactionList } from "./Transaction";
import { VideoList, VideoCreate, VideoEdit } from "./Video";
import { TagCreate, TagEdit, TagList } from "./Tag";
import {
  WebzoneUniverseCreate,
  WebzoneUniverseEdit,
  WebzoneUniverseList,
} from "./WebzoneUniverse";
import { UniverseList, UniverseCreate, UniverseEdit } from "./Universe";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
// import PostIcon from '@material-ui/icons/Book';
// import UserIcon from '@material-ui/icons/Group';
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import WebIcon from "@material-ui/icons/Web";
import LayersIcon from "@material-ui/icons/Layers";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import Message from "@material-ui/icons/Message";
import People from "@material-ui/icons/People";
import Payment from "@material-ui/icons/Payment";
import Subscriptions from "@material-ui/icons/Subscriptions";
import Whatshot from "@material-ui/icons/Whatshot";

import authReducer from "./authReducer";
import { UserEdit, UserList } from "./User";
import { PageCreate, PageEdit, PageList } from "./Page";
import polyglotI18nProvider from "ra-i18n-polyglot";
import frenchMessages from "ra-language-french";
import englishMessages from "ra-language-english";
import { MessageEdit, MessageList } from "./Message";
import {
  GlobalMessageCreate,
  GlobalMessageEdit,
  GlobalMessageList,
} from "./GlobalMessage";
import { TeaserEdit, TeaserList } from "./Teaser";
import { NewsList, NewsCreate, NewsEdit } from "./News";
// import LoginPage from './LoginPage';
// import MyLoginPage from "./MyLoginPage";
// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const theme = createMuiTheme({
  palette: {
    type: "dark", // Switching the dark mode on is a single property value change.
  },
});

const messages = {
  fr: frenchMessages,
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "fr" ? messages.fr : messages.en),
  // locale => messages[locale] ? messages[locale] : messages.fr ,
  resolveBrowserLocale()
);
// const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');
const App = () => {
  // resetCookies()
  return (
    // <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} customReducers={{ acces_token: authReducer }} loginPage={LoginPage}>
    <Admin
      i18nProvider={i18nProvider}
      theme={theme}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      customReducers={{ acces_token: authReducer }}
    >
      <Resource
        name="Teaser"
        options={{ label: "Teasers" }}
        list={TeaserList}
        // create={TeaserCreate}
        edit={TeaserEdit}
        icon={OndemandVideoIcon}
      />
      <Resource
        name="Gallery"
        list={GalleryList}
        edit={GalleryEdit}
        create={GalleryCreate}
        icon={PhotoAlbumIcon}
      />
      <Resource
        name="Video"
        list={VideoList}
        edit={VideoEdit}
        create={VideoCreate}
        icon={OndemandVideoIcon}
      />
      <Resource
        name="Universe"
        list={UniverseList}
        edit={UniverseEdit}
        create={UniverseCreate}
        icon={Whatshot}
      />
      <Resource
        name="Tag"
        list={TagList}
        create={TagCreate}
        edit={TagEdit}
        icon={BookmarksIcon}
      />
      {/* <Resource
        name="Webzone"
        options={{ label: "Web zones" }}
        list={WebzoneList}
        create={WebzoneCreate}
        edit={WebzoneEdit}
        icon={LayersIcon}
      /> */}
      <Resource
        name="WebzoneUniverse"
        options={{ label: "Web zones Universes" }}
        list={WebzoneUniverseList}
        create={WebzoneUniverseCreate}
        edit={WebzoneUniverseEdit}
        icon={LayersIcon}
      />
      <Resource
        name="Page"
        list={PageList}
        create={PageCreate}
        edit={PageEdit}
        icon={WebIcon}
      />
      <Resource
        name="Subscription"
        list={SubscriptionList}
        edit={SubscriptionEdit}
        create={SubscriptionCreate}
        icon={Subscriptions}
      />
      {/* <Resource name="SubscriptionUser" list={TransactionList} icon={Payment} /> */}
      <Resource name="Transaction" list={TransactionList} icon={Payment} />
      <Resource name="User" list={UserList} edit={UserEdit} icon={People} />
      <Resource
        name="News"
        list={NewsList}
        create={NewsCreate}
        edit={NewsEdit}
        icon={BookmarksIcon}
      />
      <Resource
        name="Messages"
        list={MessageList}
        edit={MessageEdit}
        icon={Message}
      />
      <Resource
        name="GlobalMessage"
        list={GlobalMessageList}
        create={GlobalMessageCreate}
        edit={GlobalMessageEdit}
        icon={Message}
      />

      {/* <Resource name="users" list={UserList} edit={EditGuesser} icon={UserIcon}/>
    <Resource name="posts" list={PostList } edit={PostEdit} create={PostCreate} icon={PostIcon}/> */}
    </Admin>
  );
};
export default App;
