import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { fetchUtils } from "react-admin";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Config from "../Config";
import CardUserStats from "./components/cardUserStats";
import CardSurveyStats from "./components/cardSurveyStats";
import CardSurveyBarchart from "./components/cardSurveyBarchart";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

export type GraphDataType = { name: string; Nb: number };
export type RechartGraphType = {
  date: string;
  CA: number;
  pv: number;
  amt: number;
};
const DashboardComponent = () => {
  // const dataProvider = useDataProvider();
  const [nbUsers, setNbUsers] = useState(0);
  const [nbMembers, setNbMembers] = useState(0);
  const [nbMembersPremium, setNbMembersPremium] = useState(0);

  const [surveyNbAnswered, setSurveyNbAnswered] = useState(0);
  const [surveyNbNotAnswered, setSurveyNbNotAnswered] = useState(0);
  const [refuseSurvey, setRefuseSurvey] = useState(0);
  const [preferedActressData, setPreferedActressData] = useState<
    GraphDataType[]
  >([]);

  const [winningOpinionData, setWinningOpinionData] = useState<GraphDataType[]>(
    []
  );
  const [rulesData, setRulesData] = useState<GraphDataType[]>([]);
  const [duelOpinionData, setDuelOpinionData] = useState<GraphDataType[]>([]);
  const [jokerOpinionData, setJokerOpinionData] = useState<GraphDataType[]>([]);
  const [loveRoomOpinionData, setLoveRoomOpinionData] = useState<
    GraphDataType[]
  >([]);
  const [videoFormatData, setVideoFormatData] = useState<GraphDataType[]>([]);
  const [anonimitytData, setAnonimityData] = useState<GraphDataType[]>([]);
  const [nbTemptressData, setNbTemptressData] = useState<GraphDataType[]>([]);
  const [favoriteMomentsData, setFavoriteMomentsData] = useState<
    GraphDataType[]
  >([]);
  const [realityShowOpinionData, setRealityShowOpinionData] = useState<
    GraphDataType[]
  >([]);
  const [nbEpisodesOpinionData, setNbEpisodesOpinionData] = useState<
    GraphDataType[]
  >([]);
  const [retention_stats, setRetention_stats] = useState<RechartGraphType[]>(
    []
  );

  const computeBarchartData = (
    resBody: { [x: string]: any },
    setter: (arg0: GraphDataType[]) => void
  ) => {
    delete resBody["Non répondu"];

    let nbAnswers = 0;
    const data: GraphDataType[] = [];
    Object.keys(resBody).forEach((key) => {
      nbAnswers += resBody[key];
    });
    Object.keys(resBody).forEach((key) => {
      data.push({
        name:
          key.substring(0, 20) +
          (key.length > 20 ? "..." : "") +
          "(" +
          ((resBody[key] / nbAnswers) * 100).toFixed() +
          "%)",
        Nb: resBody[key],
      });
    });
    setter(data.sort((a, b) => b.Nb - a.Nb));
  };
  useEffect(() => {
    let url = `${apiUrl}/dashboard/user_stats`;
    httpClient(url)
      .then((json) => {
        // console.log("DASHBOARD", json.body);
        setNbUsers(JSON.parse(json.body).total);
        setNbMembers(JSON.parse(json.body).members);
        setNbMembersPremium(JSON.parse(json.body).membersPremium);
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });
    url = `${apiUrl}/dashboard/survey_stats`;
    httpClient(url)
      .then((json) => {
        // console.log("DASHBOARD", json.body);
        setSurveyNbAnswered(JSON.parse(json.body).answered);
        setSurveyNbNotAnswered(JSON.parse(json.body).notAnswered);
        setRefuseSurvey(JSON.parse(json.body).refuseSurvey);

        const preferedActressBody = JSON.parse(json.body).preferedActress;
        computeBarchartData(preferedActressBody, setPreferedActressData);

        const winningOpinionBody = JSON.parse(json.body).winningOpinion;
        computeBarchartData(winningOpinionBody, setWinningOpinionData);

        const rulesBody = JSON.parse(json.body).rulesClear;
        computeBarchartData(rulesBody, setRulesData);

        const duelOpinionBody = JSON.parse(json.body).duelOpinion;
        computeBarchartData(duelOpinionBody, setDuelOpinionData);

        const jokerOpinionBody = JSON.parse(json.body).jokerOpinion;
        computeBarchartData(jokerOpinionBody, setJokerOpinionData);

        const loveroomOpinionBody = JSON.parse(json.body).loveRoomOpinion;
        computeBarchartData(loveroomOpinionBody, setLoveRoomOpinionData);

        const videoFormatOpinionBody = JSON.parse(json.body).videoFormatOpinion;
        computeBarchartData(videoFormatOpinionBody, setVideoFormatData);

        const anonymityBody = JSON.parse(json.body).anonymity;
        computeBarchartData(anonymityBody, setAnonimityData);

        const nbTemptressBody = JSON.parse(json.body).nbTemptress;
        computeBarchartData(nbTemptressBody, setNbTemptressData);

        const favoriteMomentsBody = JSON.parse(json.body).favoriteMoments;
        computeBarchartData(favoriteMomentsBody, setFavoriteMomentsData);

        const realityShowBody = JSON.parse(json.body).realityShow;
        computeBarchartData(realityShowBody, setRealityShowOpinionData);

        const nbEpisodesBody = JSON.parse(json.body).nbEpisodes;
        computeBarchartData(nbEpisodesBody, setNbEpisodesOpinionData);
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });
    url = `${apiUrl}/dashboard/retention_stats`;
    httpClient(url)
      .then((json) => {
        console.log("DASHBOARD", json.body);
        setRetention_stats(JSON.parse(json.body));
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });
  }, []);
  return (
    <>
      <Card>
        <CardHeader title="Bienvenue dans le Dashboard administration" />
        <CardContent>Retrouve les différentes stats ici...</CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <CardUserStats
            nbUsers={nbUsers}
            nbMembers={nbMembers}
            nbMembersPremium={nbMembersPremium}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardSurveyStats
            surveyNbAnswered={surveyNbAnswered}
            surveyNbNotAnswered={surveyNbNotAnswered}
            refuseSurvey={refuseSurvey}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <Grid item xs={12}>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <LineChart
            width={1000}
            height={300}
            data={retention_stats}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              label={{
                value: "abonnements",
                position: "insideBottom",
                offset: -5,
              }}
            />
            <YAxis
              label={{ value: "Nb", angle: 0, position: "left", offset: -5 }}
              // domain={[0, 4000]}
              // domain={["auto", "auto"]}
            />
            <Tooltip contentStyle={{ color: "black" }} />
            <Line
              type="monotone"
              dataKey="unsubscribedCount"
              name="désabonnés"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="newSubscriptionsCount"
              stroke="#82ca9d"
              name="nouveaux abonnés"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="initialClients"
              stroke="#ff7200"
              name="abonnés début de période"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="finalClients"
              stroke="cyan"
              name="Nb abonnés fin de période"
              activeDot={{ r: 8 }}
            />
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
            />
            {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
            <Line type="monotone" dataKey="amt" stroke="#ff7200" /> */}
          </LineChart>
          {/* </ResponsiveContainer> */}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "20px", marginBottom: "20px", padding: "10px" }}
      >
        <Grid item xs={12}>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <LineChart
            width={1000}
            height={300}
            data={retention_stats}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              label={{
                value: "Taux de retention",
                position: "insideBottom",
                offset: -5,
              }}
            />
            <YAxis
              label={{ value: "%", angle: 0, position: "left", offset: -5 }}
              // domain={[0, 4000]}
              // domain={["auto", "auto"]}
            />
            <Tooltip contentStyle={{ color: "black" }} />
            <Line
              type="monotone"
              dataKey="retentionRate"
              stroke="orange"
              name="taux de rétention"
              activeDot={{ r: 8 }}
            />

            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
            />
            {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
            <Line type="monotone" dataKey="amt" stroke="#ff7200" /> */}
          </LineChart>
          {/* </ResponsiveContainer> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Actrice Préférée"
            question="Quelles tentatrices aimerais tu revoir en saison 2 ?"
            graphData={preferedActressData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur le Gain"
            question="Le cadeau final pour le grand gagnant t’a t’il semblé à la hauteur ?"
            graphData={winningOpinionData}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur les règles"
            question="Concernant les règles du jeu, t’ont elles parues assez claires et compréhensibles ?"
            graphData={rulesData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur les duels"
            question="Concernant les duels entre tentatrices, les jeux t’ont plu?"
            graphData={duelOpinionData}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur les jokers"
            question="Avoir besoin d’un Joker gagné lors d’un duel entre tentatrices pour séduire un candidat, c’est…"
            graphData={jokerOpinionData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur la love room"
            question="Isoler un candidat dans la “Love Room” pour mieux le tenter, c’est…"
            graphData={loveRoomOpinionData}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur le format Video"
            question="Je maintiens le format vertical portrait, on est d’accord ?"
            graphData={videoFormatData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur l'anonimat"
            question="A propos de l’anonymat des candidats et des visages / voix masquées"
            graphData={anonimitytData}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur le Nombre de tentatrices"
            question="On reste sur cinq tentatrices la prochaine saison ?"
            graphData={nbTemptressData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur les moments favoris"
            question="Le/les moments que je préfère dans les épisodes"
            graphData={favoriteMomentsData}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur l'aspect télé-réalité"
            question="La télé-réalité, les histoires, les dialogues et les vannes, ça ne “vole pas haut” on est d’accord! "
            graphData={realityShowOpinionData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardSurveyBarchart
            title="Avis sur le nombre d'épisodes"
            question="11 épisodes, c'est…"
            graphData={nbEpisodesOpinionData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardComponent;
